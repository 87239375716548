import styled from 'styled-components';

export const AreaFooter = styled.div`
    background-color: #FFFFFF;
    color: #5C5C58;
    font-size: 18px;

    .colunas_footer {
        padding: 60px 10%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
        padding-bottom: 80px;
    }

        .logo_footer {
            width: 250px;
        }

        .titulo_contato_footer, .titulo_horario_footer, .titulo_redes_footer, .titulo_endereco_footer {
            font-family: 'Antonio', sans-serif;
            margin-bottom: 5px;
        }

        .contato_footer {
            list-style-type: none;
            font-family: 'Roboto', sans-serif;
        }

        .email {
            margin-bottom: 40px;
        }

        .coluna_footer_2 {
        }
        
        .horario_atendimento_footer {
            margin-bottom: 40px;
            list-style-type: none;
            font-family: 'Roboto', sans-serif;
        }

        .politicas_privacidade {
            cursor: pointer;
            text-decoration: none;
            font-family: 'Antonio', sans-serif;
        }

        a {
            text-decoration: none;
            list-style-type: none;
            color: inherit;
        }

            .politicas_privacidade:hover {
                font-weight: 700;
            }

        .container_redes_sociais {
            display: flex;
        }

            .icone_instagram_footer {
                padding-top: 10px;
                padding-bottom: 30px;
                margin-right: 15px;
                width: 40px;
            }

            .icone_facebook_footer {
                padding-top: 10px;
                padding-bottom: 30px;
                width: 40px;
            }

        .texto-endereco {
            font-family: 'Roboto', sans-serif;
            list-style-type: none;
        }

    .direitos_reservados {
        background-color: #F4F4F4;
        color: #5C5C58;
        text-align: center;
        padding: 20px;
        font-size: 14px;
    }

@media (min-width: 2100px) {
    font-size: 40px;

    .colunas_footer {
        padding: 110px 10%;
    }

    .logo_footer {
        width: 450px;
    }

    .direitos_reservados {
        font-size: 40px;
    }
}

@media (max-width: 900px) {
    .logo_footer {
        display: none;
    }
}

@media (max-width: 590px) {
    .colunas_footer {
        padding: 30px 5%;
        flex-direction: column;
        justify-content: none;
    }

        .logo_footer {
            width: 200px;
            margin-bottom: 30px;
        }

        .titulo_contato_footer, .titulo_horario_footer, .titulo_redes_footer, .titulo_endereco_footer {
            margin-bottom: 2px;
        }

        .politicas_privacidade {
            margin-bottom: 40px;
        }

        .texto-endereco {
            margin-bottom: 40px;
        }

        .direitos_reservados {
            padding-left: 50px;
            padding-right: 50px
        }
}

@media (max-width: 280px) {
        .logo_footer {
            width: 120px;
        }

        .titulo_contato_footer, .titulo_horario_footer, .titulo_redes_footer, .titulo_endereco_footer {
            margin-bottom: 2px;
        }

        .texto-endereco {
            margin-bottom: 40px;
        }
}
`;