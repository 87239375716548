import React from 'react';

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import './styled.css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import { Pagination, Navigation, Autoplay } from 'swiper/modules';

export default class CardsServicos extends React.Component {
    render() {
        return(
            <div className="container_servicos_carrossel">
                <Swiper 
                    pagination={{clickable:true}}
                    modules={[Pagination, Navigation, Autoplay]}
                    spaceBetween={30}
                    breakpoints={{
                        280: {
                            slidesPerView: 1.2,
                            spaceBetween: 10,
                        },
                        300:  {
                            slidesPerView: 1.5,
                            spaceBetween: 10,
                        },
                        500: {
                            slidesPerView: 2.8,
                        },
                        1200: {
                            slidesPerView: 4.8,
                        }}
                    }
                        loop={true}
                    autoplay={{
                        delay: 2000,
                        disableOnInteraction: false,
                      }}
                      rewind={true} 
                    className="swiper-container-servicos"
                >   
                    <SwiperSlide className="slide-item-servicos">
                        <img src='imagens/servico_consultorio.webp' className='imagem_servicos' alt='Consultas veterinárias' />
                        <p className='nome_servico'>Consultas</p>
                        <p className='desc_servico'>Veterinários altamente qualificados para realizar disgnósticos.</p>
                        <a href="https://wa.me/5551982934708">
                            <button className='cta_saiba_mais_servicos'>
                                <strong>
                                    Saiba mais
                                </strong>
                            </button>
                        </a>
                    </SwiperSlide>

                    <SwiperSlide className="slide-item-servicos">
                        <img src='imagens/servico_especialista.webp' className='imagem_servicos' alt='Especialistas veterinários' />
                        <p class="nome_servico"><strong>Especialistas</strong></p>
                        <p className='desc_servico'>Veterinários especializados em áreas da saúde.</p>
                        <a href="https://wa.me/5551982934708">
                            <button className='cta_saiba_mais_servicos'>
                                <strong>
                                    Saiba mais
                                </strong>
                            </button>
                        </a>
                    </SwiperSlide>

                    <SwiperSlide className="slide-item-servicos">
                        <img src='imagens/servico_exames.webp' className='imagem_servicos' alt='Exames veterinários' />
                        <p class="nome_servico"><strong>Exames</strong></p>
                        <p className='desc_servico'>Exames de imagem e laboratoriais diretamente na clínica.</p>
                        <a href="https://wa.me/5551982934708">
                            <button className='cta_saiba_mais_servicos'>
                                <strong>
                                    Saiba mais
                                </strong>
                            </button>
                        </a>
                    </SwiperSlide>

                    <SwiperSlide className="slide-item-servicos">
                        <img src='imagens/servico_internacao.webp' className='imagem_servicos' alt='Internação veterinária' />
                        <p class="nome_servico"><strong>Internação</strong></p>
                        <p className='desc_servico'>Veterinários especializados em áreas da saúde.</p>
                        <a href="https://wa.me/5551982934708">
                            <button className='cta_saiba_mais_servicos'>
                                <strong>
                                    Saiba mais
                                </strong>
                            </button>
                        </a>
                    </SwiperSlide>

                    <SwiperSlide className="slide-item-servicos">
                        <img src='imagens/servico_bloco.webp' className='imagem_servicos' alt='Bloco Cirúrgico Veterinário' />
                        <p class="nome_servico"><strong>Bloco Cirúrgico</strong></p>
                        <p className='desc_servico'>Blocos cirúrgicos completos com cirugiões especializados.</p>
                        <a href="https://wa.me/5551982934708">
                            <button className='cta_saiba_mais_servicos'>
                                <strong>
                                    Saiba mais
                                </strong>
                            </button>
                        </a>
                    </SwiperSlide>

                </Swiper>   
            </div>
            );
        }
    }