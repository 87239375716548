import styled from 'styled-components';

export const AreaEquipeEAtuacao = styled.div`
    display: flex;
    flex-direction: column;

    .container_equipe {
        background-color: #f4f4f4;
        color: #86BB29;
        font-family: 'Antonio', sans-serif;
        text-transform: uppercase;
        text-align: center;
        padding-top: 60px;
    }

        .conteudo_equipe {
            padding: 0 10%;
            font-size: 40px;
        }      

@media (min-width: 2100px) {
    .conteudo_equipe {
        padding: 80px 10%;
        font-size: 100px;
    }   
}

@media (max-width: 280px) {
    height: 1250px;

    .container_equipe {
        font-size: 20px;
    }

    .container_atuacao {
        font-size: 18px;
        }

        .cards_atuacao {
            margin-top: 20px;
        }

            .card_facial {
                height: 250px;
            }

            .card_corporal {
                height: 250px;
            }    

            .card_unhas {
                height: 250px;
            }
}
`;