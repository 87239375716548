import React from 'react';

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import './styled.css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import { Pagination, Navigation, Autoplay } from 'swiper/modules';

export default class Carrossel extends React.Component {
    render() {
        return(
            <div className="container_especialistas">
                <Swiper
                    pagination={{clickable:true}}
                    modules={[Pagination, Navigation, Autoplay]}
                    spaceBetween={10}
                    breakpoints={{
                        280: {
                            slidesPerView: 1.8,
                        },
                        300:  {
                            slidesPerView: 1.5,
                        },
                        500: {
                            slidesPerView: 3.5,
                        },
                        800: {
                            slidesPerView: 4.5,
                        },
                        2000: {
                            slidesPerView: 4.5,
                        }}                      
                    }
                    loop={true}
                    autoplay={{
                        delay: 4000,
                        disableOnInteraction: false,
                      }}  
                      rewind={true}               
                    className="swiper-container-especialistas"
                >   
                    <SwiperSlide className="slide-item-especialistas">
                        <img src="imagens/equipe.webp" alt="Colaborador TriAnimal" />
                        <p class="nome_especialista">
                            <strong>
                                Dra. Simone
                            </strong>
                            <br/>
                            <button className='cta_entre_em_contato_equipe'>
                                <a href='https://wa.me/5551982934708'>
                                    <strong>Entre em contato</strong>
                                </a>
                            </button>
                        </p>
                    </SwiperSlide>
                    
                    <SwiperSlide className="slide-item-especialistas">
                        <img src="imagens/equipe.webp" alt="Colaborador TriAnimal" />
                        <p class="nome_especialista">
                            <strong>
                                Dra. Emanuele
                            </strong>
                            <br/>
                            <button className='cta_entre_em_contato_equipe'>
                                <a href='https://wa.me/5551982934708'>
                                    <strong>Entre em contato</strong>
                                </a>
                            </button>
                        </p>
                    </SwiperSlide>

                    <SwiperSlide className="slide-item-especialistas">
                        <img src="imagens/equipe.webp" alt="Colaborador TriAnimal" />
                        <p class="nome_especialista">
                            <strong>
                                Felipe
                            </strong>
                            <br/>
                            <button className='cta_entre_em_contato_equipe'>
                                <a href='https://wa.me/5551982934708'>
                                    <strong>Entre em contato</strong>
                                </a>
                            </button>
                        </p>
                    </SwiperSlide>

                    <SwiperSlide className="slide-item-especialistas">
                        <img src="imagens/equipe.webp" alt="Colaborador TriAnimal" />
                        <p class="nome_especialista">
                            <strong>
                                Juliete
                            </strong>
                            <br/>
                            <button className='cta_entre_em_contato_equipe'>
                                <a href='https://wa.me/5551982934708'>
                                    <strong>Entre em contato</strong>
                                </a>
                            </button>
                        </p>
                    </SwiperSlide>

                    <SwiperSlide className="slide-item-especialistas">
                        <img src="imagens/equipe.webp" alt="Colaborador TriAnimal" />
                        <p class="nome_especialista">
                            <strong>
                                Juliete
                            </strong>
                            <br/>
                            <button className='cta_entre_em_contato_equipe'>
                                <a href='https://wa.me/5551982934708'>
                                    <strong>Entre em contato</strong>
                                </a>
                            </button>
                        </p>
                    </SwiperSlide>

                    <SwiperSlide className="slide-item-especialistas">
                        <img src="imagens/equipe.webp" alt="Colaborador TriAnimal" />
                        <p class="nome_especialista">
                            <strong>
                                Juliete
                            </strong>
                            <br/>
                            <button className='cta_entre_em_contato_equipe'>
                                <a href='https://wa.me/5551982934708'>
                                    <strong>Entre em contato</strong>
                                </a>
                            </button>
                        </p>
                    </SwiperSlide>

                </Swiper>   
            </div>
            );
        }
    }