import React from 'react';

import { AreaCta } from './styled';

const Cta = () => {
    return (
        <AreaCta>
        <div class="container_cta" id='contato'>
            <p class="titulo_secao_cta">
                <strong>Precisa agendar consultas,</strong> exames, estética, e mais?
            </p>

            <a href='https://wa.me/5551999927401' className='cta_entre_em_contato_secao_cta'>
                <button>
                    <strong>INICIAR CONVERSA AGORA</strong>
                </button>
            </a>
        </div>
        </AreaCta>
    );
}

export default Cta;