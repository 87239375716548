import React, { useRef, useState } from 'react';

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import './styled.css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import { Pagination, Navigation, Autoplay } from 'swiper/modules';


export default class Carrossel extends React.Component {
    render() {
        return(
            <div className="container_banner_header">
                <Swiper 
                    pagination={{clickable:true}}
                    modules={[Pagination, Navigation, Autoplay]}
                    spaceBetween={10}
                    breakpoints={{
                        280: {
                            slidesPerView: 1,
                        },
                        300:  {
                            slidesPerView: 1,
                        },
                        500: {
                            slidesPerView: 1,
                        },
                        800: {
                            slidesPerView: 1,
                        }}
                    }
                    loop={true}
                    autoplay={{
                        delay: 2000,
                        disableOnInteraction: false,
                      }}
                    
                    className="swiper-container-banner-header"
                >   
                    <SwiperSlide className="slide-item-banner-header" id='slide_header_1'>
                        <p class="titulo_banner_header">
                            <strong>
                                Emergência <br/>24 horas
                            </strong>
                            <br/>
                            <a href='https://wa.me/5551999927401'>
                                <button className='cta_entre_em_contato_banner_header'>
                                    <strong>Entre em contato por WhatsApp</strong>
                                </button>
                            </a>

                            <br/>

                            <a href='tel:+5551999927401'>
                                <button className='cta_entre_em_contato_banner_header'>
                                    <strong>Ligue agora (51) 99992.7401</strong>
                                </button>
                            </a>
                        </p>
                    </SwiperSlide>

                    <SwiperSlide className="slide-item-banner-header" id='slide_header_2'>
                        <p class="titulo_banner_header">
                            <strong>
                                Clínica <br/>veterinária
                            </strong>
                            <br/>

                            <a href='https://wa.me/5551999927401'>
                                <button className='cta_entre_em_contato_banner_header'>
                                    <strong>Entre em contato E AGENDE</strong>
                                </button>
                            </a>
                        </p>
                    </SwiperSlide>

                </Swiper>   

            <button className='botao_flutuante_whatsapp'>
                <a href="https://wa.me/5551999927401"><img src="imagens/whatsapp_flutuante.webp" id="botao_flutuante_whatsapp" alt="botão whatsapp" /></a>
            </button>
            </div>
            );
        }
    }