import styled from 'styled-components';

export const AreaEmegencia = styled.div`
    background: url(imagens/fullbanner2.png) right center;
    background-size: cover;
    padding: 150px 10%;

    .container_emergencia {
        display: flex;
        flex-direction: column;
    }
        .titulo_emergencia {
            color: #f4f4f4;
            font-size: 80px;
            font-family: 'Antonio', sans-serif;
            padding-bottom: 20px;
            width: 30%;
            text-transform: uppercase;
        }

        .subtitulo_emergencia {
            color: #86BB29;
            font-size: 40px;
            font-family: 'Antonio', sans-serif;
            padding-bottom: 20px;
            width: 45%;
        }

        .cta_entre_em_contato_emergencia {
            font-family: 'Antonio', sans-serif;
            background-color: #f4f4f4;
            font-size: 30px;
            color: #86BB29;
            height: 50px;
            width: 40%;
            cursor: pointer;
            margin-top: 10px;
            padding: 2px;
            text-transform: uppercase;
            border: none;
            border-radius: 20px;
        }

            .cta_entre_em_contato_emergencia:hover {
                box-shadow: 2px 2px 5px black;
                background-color: #86BB29;
                color: #f4f4f4;
            }

@media (min-width: 2100px) {
    .titulo_emergencia {
        font-size: 110px;
        padding-bottom: 40px;
    }

    .subtitulo_emergencia {
        font-size: 70px;
        padding-bottom: 40px;
    }

    .cta_entre_em_contato_emergencia {
        font-size: 50px;
        height: 90px;
        width: 40%;
        border-radius: 50px;
    }
}

@media (max-width: 1200px) {   
    .cta_entre_em_contato_emergencia {
        width: 80%;
        margin-bottom: 0;
    }
}  

@media (max-width: 900px) {   
    background: url(imagens/fullbanner2.png) center center;

    .titulo_emergencia {
        width: 60%;
    }

    .subtitulo_emergencia {
        width: 60%;
    }

    .cta_entre_em_contato_emergencia {
        width: 80%;
        margin-bottom: 0;
    }
}    

@media (max-width: 590px) {
    background: url(imagens/fullbanner2_mobile.png) right center;
    background-size: cover;
    padding: 0px 10%;
    padding-bottom: 60px;

        .titulo_emergencia {
            font-size: 50px;
            padding-top: 500px;
            padding-bottom: 20px;
            width: 90%;
        }

        .subtitulo_emergencia {
            font-size: 30px;
            padding-bottom: 10px;
            width: 90%;
        }

    .cta_entre_em_contato_emergencia {
        font-size: 25px;
        width: 100%;
        margin-bottom: 0;
    }
}

@media (max-width: 290px) {
    .titulo_emergencia {
        font-size: 40px;
        padding-top: 300px;
    }

        .subtitulo_emergencia {
            font-size: 20px;
        }

    .cta_entre_em_contato_emergencia {
        font-size: 20px;
        height: 60px;
    }
}
`;