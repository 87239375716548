import React, { useEffect, useState } from 'react';

import './App.css';
import Header from './Header';
import Footer from './Footer';
import Services from './Servicos';
import Emergency from './Emergency';
import Team from './Team';
import Cta from './Cta';


function App() {

  const [ativaCor, setAtivaCor] = useState(false);

  useEffect(function(){
    function posicaoScroll(){
      if(window.scrollY > 40){
        setAtivaCor(true);
      } else {
        setAtivaCor(false);
      }
    }

    window.addEventListener('scroll', posicaoScroll);
  }, []);

  return (
    <>
      <Header acao={ativaCor} />
      <Services />
      <Emergency />
      <Cta />
      <Footer />
    </>
  );
}

export default App;