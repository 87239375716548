import React from 'react';

import { AreaFooter } from './styled';

const Footer = () => {
    return (
        <AreaFooter>
            <div className='container_footer'>
                <div className='colunas_footer'>
                    
                    <div className='coluna_footer_1'>
                        <img src="imagens/logo.webp" className='logo_footer' alt="" />
                    </div>

                    <div className='coluna_footer_2'>
                        <ul className='contato_footer'>
                            <p className='titulo_contato_footer'>Contatos</p>
                            <li>
                                <a href='tel:+5551082934708'>
                                    <p className='dado_contato'>51 98293.4708</p>
                                </a>
                                <a href='tel:+5551999927401'>
                                    <p className='dado_contato'>51 99992.7401</p>
                                </a>
                            </li>
                            <li className='email'>
                                <a href='mailto:atendimento@trianimal.com.br'>
                                    <p className='dado_contato'>atendimento@trianimal.com.br</p>
                                </a>
                            </li>
                        </ul>
                        <ul className='horario_footer'>
                            <p className='titulo_horario_footer'>Horário de funcionamento:</p>
                            <li className='horario_atendimento_footer'>
                            Atendimento 24 horas
                            </li>
                        </ul>
                        <a href='/politicas-privacidade'>
                            <p className='politicas_privacidade'>Politicas de Privacidade</p>
                        </a>
                    </div>

                    <div className='coluna_footer_3'>
                        <ul className='redes_footer'>
                            <p className='titulo_redes_footer'>Nossas Redes</p>
                            <li className='container_redes_sociais'>
                                <a href='https://www.instagram.com/trianimalpet/'>
                                    <img src="imagens/icone_instagram.webp" className='icone_instagram_footer' alt="" />
                                </a>
                                <a href='https://www.facebook.com/trianimalpet'>
                                    <img src="imagens/icone_facebook.webp" className='icone_facebook_footer' alt="" />
                                </a>
                            </li>
                        </ul>
                        <ul>
                            <p className='titulo_endereco_footer'>Como chegar</p>
                            <li className='texto-endereco'>
                                <strong>Av. Tramandaí, 430</strong> - Ipanema, <br/>Porto Alegre - RS, 91760-050
                            </li>
                        </ul>
                    </div>

                </div>
                <p className='direitos_reservados'>
                    Todos os direitos reservados | Desenvolvido por <a href='https://vicaagencia.com.br/'>Vica Estratégias Digitais</a>.
                </p>
            </div>
        </AreaFooter>
    );
}

export default Footer;