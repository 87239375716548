import styled from 'styled-components';

export const AreaCta = styled.div`
    background: url(imagens/fullbanner1.png) right center;
    background-size: cover;
    padding: 120px 10%;

    .container_cta {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
        .titulo_secao_cta {
            color: #f4f4f4;
            font-size: 50px;
            text-transform: uppercase;
            font-family: 'Antonio', sans-serif;
            padding-bottom: 20px;
        }

        .cta_entre_em_contato_secao_cta {
            font-family: 'Antonio', sans-serif;
            background-color: #f4f4f4;
            font-size: 30px;
            color: #000000;
            height: 50px;
            width: 50%;
            cursor: pointer;
            margin-top: 20px;
            padding: 2px;
            text-transform: uppercase;
            border: none;
            border-radius: 20px;
            text-align: center;
        }

        button {
            cursor: pointer;
        }

            .cta_entre_em_contato_secao_cta:hover {
                box-shadow: 2px 2px 5px black;
                background-color: #000000;
                color: #f4f4f4;
            }

@media (min-width: 2100px) {
    padding: 200px 10%;

    .titulo_secao_cta {
        font-size: 80px;
    }

    .cta_entre_em_contato_secao_cta {
        font-size: 60px;
        height: 110px;
        margin-top: 60px;
        border: none;
        border-radius: 50px;
    }
}

@media (max-width: 1200px) {   
    padding: 100px 15%; 

    .cta_entre_em_contato_secao_cta {
        width: 80%;
    }
}     

@media (max-width: 590px) {
    padding: 60px 10%;
            .titulo_secao_cta {
                font-size: 45px;
            }

            .cta_entre_em_contato_secao_cta {
                font-size: 25px;
                width: 100%;
                height: 40px;
            }
}

@media (max-width: 290px) {
    .titulo_secao_cta {
        font-size: 30px;
    }

    .cta_entre_em_contato_secao_cta {
        font-size: 20px;
        width: 80%;
        height: 40px;
    }
`;