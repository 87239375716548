import styled from 'styled-components';

export const AreaHeader = styled.div`
  height: 800px;  
  background-color: #f4f4f4;
  font-family: 'Antonio', sans-serif;

  header {
    width: 100%;
    background-color: #f4f4f4;
    padding: 40px 10%;
    margin-top: 80px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: fixed;
    z-index: 99;
    transition: all ease 0.3s;
  }

    .ativaCor {
      height: 100px;
      margin-top: 0;
      transition: 0.5s;

        .logo_header {
          img {
            width: 150px;
            position: none;
            top: 15px;
          }    
        }
      }
  
    .logo_header {
      flex-grow: 0;

      img {
        position: absolute;
        top: -50px;
        width: 300px;
        cursor: pointer;
      }
    } 

  .container_banner_principal {
    width: 100%;
    padding: 20px 10%;
    display: flex;
    flex-direction: row;
    position: absolute;
    top: 180px;
  }

    .main_textos {
      width: 50%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: right;
    }

      .titulo_banner {
        font-size: 60px;
        color: #000000;
        text-transform: uppercase;
        font-weight: 100;
        padding-top: 100px;
        padding-bottom: 20px;
      }

      .vetor_banner_header {
        width: 650px;
        position: relative;
        left: -180px
      }

      strong {
        font-weight: 700;
      }

@media (min-width: 2100px) {
  height: 1000px;

  .ativaCor {
    height: 150px;

      .logo_header {
        img {
          width: 250px;
        }    
      }
    }

  .logo_header {
    flex-grow: 0;

    img {
      position: absolute;
      top: -50px;
      width: 500px;
      cursor: pointer;
    }
  }

  .titulo_banner {
    font-size: 110px;
    padding-top: 170px;
    padding-bottom: 20px;
  }

  .vetor_banner_header {
    display: none;
  }
}

@media (max-width: 1200px) {
  .vetor_banner_header {
    width: 500px;
    left: -120px
  }
}

@media (max-width: 900px) {
  height: 900px; 

  .container_banner_principal {
    padding: 0 5%;
    flex-direction: column;
    justify-content: center;
  }

  .main_textos {
    width: 100%;
  }

    .titulo_banner {
      font-size: 40px;
    }

  .vetor_banner_header {
    display: none;
  }
}

@media (max-width: 590px) {
  height: 800px;  

  header {
    padding: 40px 5%;
  }

    .ativaCor {
      height: 100px;
      margin-top: 0;
      transition: 0.5s;

        .logo_header {
          img {
            width: 150px;
            top: 15px;
          }    
        }

        .nav-icon {
          display: block;
          position: absolute;
          top: 20px;
          right: 0;
          transform: translate(-100%, 60%);
          font-size: 1.8rem;
      }
      }

      .logo_header {
        flex-grow: 0;
  
        img {
          width: 200px;
        }
      }

      .container_banner_principal {
        padding: 0 5%;
        flex-direction: column;
        justify-content: center;
      }

  .main_textos {
    width: 80%;
  }

    .titulo_banner {
      font-size: 40px;
      padding-top: 0;
      padding-bottom: 30px;
    }

    .vetor_banner_header {
      display: none;
    }
}  

@media (max-width: 290px) {
  .ativaCor {
    height: 50px;

      .logo_header {
        img {
          width: 110px;
          top: 35px;
        }    
      }
    }

  .logo_header {
    flex-grow: 0;

    img {
      width: 150px;

      .main_textos {
        width: 100%;
      }
    
        .titulo_banner {
          font-size: 20px;
        }
    
    }
  }
}
`;

