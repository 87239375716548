import React from 'react';

import { AreaServicos } from './styled';
import CardsServicos from './CardsServicos/index'

const Servicos = () => {
    return (
        <AreaServicos id="servicos">
        <div class="container_servicos">
            <div>
                <h4 class="titulo_servicos"> <strong>Conheça os nossos serviços</strong></h4>
            </div>
            
            <CardsServicos />
            
        </div>
    </AreaServicos>
    );
}

export default Servicos;




