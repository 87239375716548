import React from 'react';
import { AreaHeader } from './styled';
import NavBar from './Nav/NavBar'
import Carrossel from './CarrosselEspecialistas/carrossel'

function Header ({acao}) {
    return (
        <AreaHeader>
            <div className='container_header'>
                <header className={ acao ? 'ativaCor' : '' }>
                    <h1 className='logo_header'>
                        <a href="/">
                            <img src="imagens/logo.webp" alt="Logo TriAnimal" />
                        </a>
                    </h1>
                    <NavBar />
                </header>

                <main className='container_banner_principal'>
                    <div className='main_textos'>
                        <h2 className='titulo_banner'><strong>Seu pet saudável</strong> e seguro, 24 horas por dia.</h2>
                        <img src="imagens/vetor_pets.webp" className='vetor_banner_header' alt="saúde pet" />
                    </div>

                    <div className='container_imagem_banner_principal'>
                        <Carrossel />
                    </div>        
                </main>
            </div>
        </AreaHeader>
    );
}

export default Header;