import React from 'react';

import { AreaEmegencia } from './styled';

const Emergency = () => {
    return (
        <AreaEmegencia id="emergencia-24-horas">
        <div class="container_emergencia">
            <p class="titulo_emergencia">
                <strong> Emergência 24 horas </strong>
            </p>

            <p className='subtitulo_emergencia'>
                <strong>Conte conosco a qualquer momento do seu dia, para garantir  a saúde 100% do seu pet</strong>
            </p>

            <a href='https://wa.me/5551999927401'>
                <button className='cta_entre_em_contato_emergencia'>
                    <strong>Entre em contato por WhatsApp</strong>
                </button>
            </a>

            <a href='tel:+5551999927401'>
                <button className='cta_entre_em_contato_emergencia'>
                    <strong>Ligue agora (51) 99992.7401</strong>
                </button>
            </a>
        </div>
        </AreaEmegencia>
    );
}

export default Emergency;