import styled from 'styled-components';

export const AreaServicos = styled.div`
    background-color: #86BB29;

    .container_servicos {
        display: flex;
        flex-direction: column;
    }

        .titulo_servicos {
            color: #f4f4f4;
            text-align: center;
            font-size: 60px;
            font-family: 'Antonio', sans-serif;
            padding-top: 100px;
            text-transform: uppercase;
        }

@media (min-width: 2100px) {
    .titulo_servicos {
        font-size: 100px;
    }
}

@media (max-width: 590px) {
    .titulo_servicos {
        font-size: 30px;
        padding-top: 80px;
    }
}
`;